<template>
    <div class="case">
        <dl v-for="(item,i) in data" :key="i">
            <dt class="bg-img" :style="{'background-image':'url('+item.img+')'}"></dt>
            <dd>
                <p class="tag">{{item.tag}}</p>
                <p class="name">{{item.name}}</p>
                <p class="account" v-if="item.account">{{item.account}}</p>
            </dd>
        </dl>
    </div>
</template>
<script>

export default {
    props:["data"],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="less">
.case{
    padding:50px 10%;
    width:80%;
    display: flex;
    justify-content: space-between;
    dl{
        width:150px;
        height:150px;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        dd{
            position: absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            text-align: center;
            .tag{
                margin-top:50px;
            }
        }
        dt{
            width:100%;
            height:100%;
            img{
                width:100%;
                height:100%;
            }
        }
    }
}
</style>