<template>
    <div class="concat">
        <div class="square">
            <div class="avatar">
                <p class="img bg-img" :style="{'background-image':'url('+data.avatar+')'}"></p>
                <h3 class="fs20">{{data.title}}</h3>
                <p class="fs14">{{data.subTitle}}</p>
            </div>
            <div class="content">
                <dl>
                    <dd class="name">客服</dd><dd class="qq">QQ (点击聊天)</dd><dd class="phone">手机</dd><dd class="email">邮箱</dd>
                </dl>
                <dl v-for="(item,i) in data.info" :key="i">
                    <dd class="name">{{item.name}}</dd>
                    <dd class="qq"><a class="blue fs12" :href="'tencent://message/?uin='+item.qq">{{item.qq}}</a></dd>
                    <dd class="phone">{{item.phone}}</dd>
                    <dd class="email">{{item.email}}</dd>
                </dl>
                <dl class="qrcode">
                    <dd v-for="(item,i) in data.qrcode" :key="i"><img :src="item.code"/></dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"]
}
</script>
<style scoped lang="less">
.concat{
    margin:50px 0;
    .square{
        padding:50px 0 0 0;
        width:80%;
        margin:0 10%;
        .content{
            margin:0 auto;
            width:800px;
            padding:150px 0 20px 0;
            border:4px double #d9d9d9;
            position:relative;
            top:-120px;
            dl:first-child{
                border-top:none;
                dd{
                    color:#666;
                }
            }
            dl{
                margin:0 5%;
                border-top:1px dotted #d9d9d9;
                display: flex;
                justify-content: space-between;
                .qq,.phone,.email{
                    width:30%;
                }
                .name{
                    flex:1;
                }
                dd{
                    line-height: 40px;
                    font-size:12px;
                    text-align: center;
                    a{
                        text-decoration: none;
                        
                    }

                }

            }
            .qrcode{
                margin-top:20px;
                display: flex;
                justify-content: center;
                border:none;
                dd{
                    margin:0 100px;
                    width:150px;
                    img{
                        width:100%;
                    }
                    line-height: inherit;
                }
            }
        }
        .avatar{
            position: relative;
            z-index: 2;
            margin:0 auto;
            text-align: center;
            width:200px;
            .img{
                display: inline-block;
                width:100px;
                height:100px;
            }
            
            h3{
                margin:15px 0 5px 0;
            }
        }
    }
}
</style>