<template>
    <div class="home">
        <Menu :data="homeData.service"/>

        <Head :data="homeData.head"/>
       
        <Slogan :data="homeData.slogan"/>
        
        <Service :data="homeData.service"/>

        <ModuleHead :data="homeData.process.header"/>
        <Process :data="homeData.process.items"/>
        
        <!--
        <ModuleHead :data="homeData.cases.header"/>
        <Cases :data="homeData.cases.items"/>
        <Employee/>
        -->
        
        <ModuleHead :data="homeData.feature.header" :bg="'#f9f9f9'"/>
        <Feature :data="homeData.feature.items"/>

        <Concat :data="homeData.concat"/>

        <Message :data="homeData.message"/>
        
        
        <Foot/>
        
    </div>
</template>
<script>
import Head from '@/components/common/VideoHeader/Pc.vue'
import Service from '@/components/home/service/Pc.vue'
import Process from '@/components/home/Process/Pc.vue'
import Feature from '@/components/home/Feature/Pc.vue'
import Message from '@/components/home/Message/Pc.vue'
import Concat from '@/components/home/Concat/Pc.vue'
import Cases from '@/components/home/Cases/Pc.vue'
import Employee from '@/components/home/Employee/Pc.vue'

import Foot from '@/components/common/Foot/Pc.vue'
import ModuleHead from '@/components/common/ModuleHead/Pc.vue'
import Slogan from '@/components/common/Slogan/Pc.vue'
import Menu from '@/components/common/Menu/Pc.vue'

import homeData from '@/data/home.js'
export default {
    components:{
        Head,Slogan,Service,Process,Feature,Message,Concat,Cases,Employee,
        Foot,ModuleHead,Menu
    },
    data(){
        return {
            homeData,
        }
    }
}
</script>