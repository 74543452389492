<template>
    <div class="process">
        <div class="main">
            <div  v-for="(item,i) in data" :key="i" :class="['square','bg-img',item.class]" :style="{'background-image':'url('+item.img+')'}">
                <div class="unit">
                    <div class="text">
                        <h1 class="fs20">{{item.title}}</h1>
                        <h2 class="fs14">{{item.subTitle}}</h2>
                        <p class="main-txt">{{item.desc}}</p>
                    </div>
                    
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
export default {
    props:["data"]
}
</script>
<style scoped lang="less">
.process{
    margin-top:100px;
    margin-bottom:50px;
    .main{
        padding:0 10%;
        width:80%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .square{
            height:250px;
            margin-bottom:40px;
            display: flex;
            justify-content: flex-start;
            border:1px solid #e9e9e9;
            .unit{
                width:150px;
                background:rgba(255,255,255,1);
                padding:20px;
                
                h1{
                    margin-top:30px;
                }
                h2{
                    padding:5px 0 40px 0;
                    font-weight: normal;
                }
                p{
                    
                }
            }
        }
        .square-a{
            width:30%;
        }
        .square-b{
            width:calc(100% - 60% - 80px);
        }
        .square-c{
            width:calc(100% - 30% - 40px);
        }
    }
}
</style>