<template>
    <div class="service">
        <div class="main">
            <div :class="['unit',activeIdx==i?'active':'']" v-for="(item,i) in data" :key="i" 
                @click="$router.push(item.path)"
                @mouseenter="activeIdx=i">
                <dl>
                    <dt><span class="fs20 title">{{item.title}}</span> <small class="fs14">{{item.subTitle}}</small></dt>
                    <dd><span v-for="(child,j) in item.children" :key="j" class="main-txt">{{child}} </span></dd>
                </dl>
                <p class="image bg-img" :style="{'background-image':'url('+item.image+')'}"></p>
            </div>
        </div>
    </div>
</template>
<script>
import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    }
}
</script>
<style scoped lang="less">
.service{
    margin-top:100px;
    background:#f9f9f9;
    padding:50px 10%;
    .main{
        display: flex;
        justify-content: space-between;
        .unit.active{
            box-shadow: 0 0 5px 3px #d9d9d9;
        }
        .unit{
            width:20%;
            background:white;
            border-radius: 5px;
            position: relative;
            padding:25px;
            cursor: pointer;
            
            dl{
                
                height:100px;
                dt{
                    .title{
                        font-weight: bold;
                    }
                    margin-bottom:10px;
                }
            }
            .image{
                height:200px;
                width:80%;
                padding:0 10%;
            }
            
        }
    }
}
</style>