<template>
    <div class="feature">
        <div class="main">
            <div :class="['unit',activeIdx==i?'active':'']" @mouseenter="activeIdx=i" v-for="(item,i) in data" :key="i">
                <div class="square bg-img" :style="{'background-image':'url('+item.img+')'}"></div>
                <div class="square text">
                    <div class="item">
                        <h3 class="fs20">{{item.title}}</h3>
                        <h4 class="fs14">{{item.subTitle}}</h4>
                        <p v-for="(option,j) in item.options" :key="j"  class="main-txt">{{option}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:["data"],
    data(){
        return {
            activeIdx:0,
        }
    }
}
</script>
<style scoped lang="less">
.feature{
    padding-top:100px;
    padding-bottom: 50px;
    background:#f9f9f9;
    .main{
        width:80%;
        padding:0 10%;
        display: flex;
        justify-content: space-between;
        
        flex-wrap: wrap;
        .unit.active{
            box-shadow: 0 0 5px 3px #d9d9d9;
        }
        .unit{
            cursor: pointer;
            width:calc(50% - 50px - 10px);
            padding:25px;
            border-radius: 5px;
            background:white;
            margin-bottom:20px;
            display: flex;
            justify-content: space-between;
            .bg-img{
                width:250px;
                height:200px;
                margin-right:25px;
            }
            .text{
                flex:1;
            }
        }
        .square{  
            h3{
                margin-top:20px;
            }
            h4{
                margin:5px 0 10px 0;
                font-weight: normal;
            }
        }
    }
}
</style>