<template>
    <div class="head bg-img" :style="{'background-image':'url('+data.img+')'}">
        <div class="top" v-if="false">
            <p class="btns">
                <span class="menu" v-for="(btn,i) in data.btns" :key="i">{{btn.label}}</span>
            </p>
        </div>
        <div class="text">
            <h1 class="fs45 blue"><Icons :name="data.logo" color="#0071e3" :height="50" :width="50"/></h1>
            <h2 class="fs30">{{data.subTitle}}</h2>
            <p class="fs20">{{data.desc}}</p>
        </div>

        <div class="video">
            <video  muted autoplay loop>
                <source :src="data.video" type="video/mp4" />
            </video>
        </div>
    </div>
</template>
<script>
    import Icons from '@/components/common/Icons.vue'
export default {
    components:{
        Icons
    },
    props:["data"]
}
</script>
<style scoped lang="less">
.head{
    height:500px;
    position: relative;
    overflow: hidden;
    .video{
        width:100%;
        position: absolute;
        top:0;
        left:0;
        
        video{
            width:100%;
            border:none;
        }
    }
    .top{
        position:absolute;
        top:0;
        left:0;
        width:90%;
        padding:20px 5%;
        z-index: 2;
        display: flex;
        justify-content: flex-end;
        .identifier{
            span{
                color:white; /*#0071e3*/
                margin-right:20px;
                font-size:16px;
                
            }
        }
        .btns{

        }
    }
    .text{
        text-align: center;
        width:500px;
        margin:0px auto;
        padding-top:50px;
        z-index: 2;
        position: relative;
        
        h1{
            margin-top:100px;
        }
        h2{
            margin:0px 0 10px 0;
            color:white;
            letter-spacing: 10px;
        }
        p{
            color:white;

        }
    }
}
</style>